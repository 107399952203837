$(function() {
    $('.btn-toggle-menu').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.toggle-menu').slideToggle();
    });
});


$(function() {
    $('.aside-filter_title').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.aside-filter_nav').slideToggle();
    });
});

$(function() {

    $('.category-navigation .btn-toggle-menu').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.category-navigation .toggle-menu').slideToggle();
    });
});

$(function() {

    $('.btn-filters').click(function(e) {
        
        e.preventDefault();

        $('.archive-products_aside').addClass('opened');
        
    });

});

$(function() {
    $('.btn-close').click(function(e) {
        
        e.preventDefault();

        $('.archive-products_aside').removeClass('opened');
        
    });
});


$(function() {
    $('.head-login_box.atend .head-login_link').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.head-login_box.atend .head-login_box-opt').slideToggle();
    });
});

$(function() {
    $('.head-login_box.login .head-login_link').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('head-login_box--active');
        
        $('.head-login_box.login .head-login_box-opt').slideToggle();
    });
});


$(function() {
    $('.category-navigation .toggle-cat').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.menu-filtro-de-categorias').slideToggle();
    });
});


$(function() {
    $('.toggle-price').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.cat-price').slideToggle();
    });
});

