function initializeLazyLoad() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy, source.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    var lazyImage = entry.target;

                    // Para elementos IMG que ainda têm a classe 'lazy'
                    if (lazyImage.tagName === "IMG" && lazyImage.classList.contains('lazy')) {
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.classList.remove("lazy");
                        lazyImageObserver.unobserve(lazyImage);
                    }

                    // Para elementos SOURCE dentro de <picture>
                    if (lazyImage.tagName === "SOURCE" && lazyImage.classList.contains('lazy')) {
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        var parentPicture = lazyImage.parentNode;
                        
                        // Encontra o elemento <img> associado dentro do mesmo <picture>
                        var imgTag = parentPicture.querySelector('img');
                        if (imgTag) {
                            // Força a atualização do <img> para garantir que o navegador reavalie o <source>
                            imgTag.src = imgTag.dataset.src;
                            imgTag.onload = function() {
                                lazyImage.classList.remove("lazy");
                                imgTag.classList.remove("lazy");
                            };
                        }
                        
                        lazyImageObserver.unobserve(lazyImage);
                    }
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Fallback para navegadores que não suportam IntersectionObserver
        lazyImages.forEach(function(lazyImage) {
            if (lazyImage.tagName === "IMG" && lazyImage.classList.contains('lazy')) {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove("lazy");
            } else if (lazyImage.tagName === "SOURCE" && lazyImage.classList.contains('lazy')) {
                lazyImage.srcset = lazyImage.dataset.srcset;
                // Aqui não precisamos recarregar o <img> porque estamos no fallback
            }
        });
    }
}


// Inicializa o lazy load após o carregamento do DOM
document.addEventListener("DOMContentLoaded", initializeLazyLoad);
