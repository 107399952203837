var header = $('.item-fixed');
var headerPos = $(header).offset().top + $(header).innerHeight();

if(window.pageYOffset > headerPos) {
    header.addClass('fixed');
} else {
    header.removeClass('fixed');
}

$(window).scroll(function () {
    if ($(this).scrollTop() > headerPos) {
        header.addClass('fixed');
    } else {
        header.removeClass('fixed');
    }
});